import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notificationCount: 0
};

export const NotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        ChangeNotificationCount: (state) => {
            state.notificationCount += 1
        }
    },
});

export const {
    ChangeNotificationCount
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
