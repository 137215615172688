import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { IErrorToastProps, IErrorToastState } from "./error-toast.model";

export default class ErrorToast extends React.Component<
IErrorToastProps,
IErrorToastState
> {
  constructor(props: IErrorToastProps) {
    super(props);
    this.state = {
      message: props.message,
    };
  }

  render() {
    const { onConfirm, buttonText } = this.props;
    const { message } = this.state;
    return (
      <div>
        <SweetAlert
          error
          title={message}
          onConfirm={onConfirm}
          confirmBtnText={buttonText}
          focusCancelBtn={false}
          focusConfirmBtn={false}
        />
      </div>
    );
  }
}
