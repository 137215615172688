import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prevState: {},
  filterState:{},
};

export const DataPreviewSlice = createSlice({
  name: 'dataPreview',
  initialState,
  reducers: {
    ChangePrevState: (state, action) => {
      state.prevState = action.payload
    },
    ChangeFilterState: (state, action) => {
      state.filterState = action.payload
    },
  },
});

export const {
    ChangePrevState,
    ChangeFilterState
} = DataPreviewSlice.actions;

export default DataPreviewSlice.reducer;
