import { LogLevel } from "@azure/msal-browser";

const getMsalConfig = () => {
  const {
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY,
    REACT_APP_KNOWNAUTHORITIES,
    REACT_APP_PASSWORDRESETURL,
  } = process.env;

  return {
    auth: {
      clientId: `${REACT_APP_CLIENT_ID}`,
      authority: `${REACT_APP_AUTHORITY}`,
      redirectUri: "/",
      knownAuthorities: REACT_APP_KNOWNAUTHORITIES.split(','),
      passwordResetURL: `${REACT_APP_PASSWORDRESETURL}`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              //  console.info(message);
              return;
            case LogLevel.Verbose:
              //console.debug(message);
              return;
            case LogLevel.Warning:
              //console.warn(message);
              return;
          }
        },
      },
    },
  };
};

export const msalConfig = getMsalConfig();

export const loginRequest = {
  scopes: [],
};

export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Herev1.0/me",
};
