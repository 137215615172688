import React from "react";
import logo from '../../assets/images/logos/MetrixLogoGrey.png'

const PageLoader = () => {
    return (
        <>
            <div className="page-loader">
                <div className="wrapper">
                    <div>
                        <img src={logo} alt="" />
                    </div>
                    <div className="mt-3">
                        <div className="loader">
                            <div className="loaderBar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default PageLoader;