import { combineReducers } from "redux";
import settings from "./settings/Reducer";
import CustomizerReducer from '../store/customizer/CustomizerSlice';
import MeterSettingReducer from '../store/meterSetting/MeterSetting';
import NotificationReducer from "../store/notification/notificationSlice";
import DataPreviewReducer from "../store/dataPreview/DataPreviewSlice";

const Reducers = combineReducers({
  settings,
  customizer: CustomizerReducer,
  meterSetting: MeterSettingReducer,
  notification: NotificationReducer,
  dataPreview : DataPreviewReducer
});

export default Reducers;
