import {
    LOGO_BG,
    NAVBAR_BG,
    SIDEBAR_BG,
    THEME,
    DIRECTION,
    SIDEBAR_POSITION,
    HEADER_POSITION,
    LAYOUT,
    SIDEBAR_TYPE,
    PERMISSIONS,
    ASSIGNED_PERMISSIONS,
    ABILITIES,
    SELECTED_TIMEZONE,
    SELECTED_DASHBOARD_FILTER,
    USER_SETTING
} from '../constants';
import ability from '../../ability';
import { LOCALSTORAGE_KEY } from '../../utils';

const storeStateInLocalStorage = (key: string, payload: any) => localStorage.setItem(key, JSON.stringify(payload));

export const setUserSetting = (payload: any) => {
    storeStateInLocalStorage(USER_SETTING, payload);
    return {
        type: USER_SETTING,
        payload
    }
}

export const setLogoBg = (payload: any) => {
    localStorage.setItem('setLogoBg', payload);
    return {
        type: LOGO_BG,
        payload
    }
}

export const setNavbarBg = (payload: any) => {
    localStorage.setItem('setNavbarBg', payload);
    return {
        type: NAVBAR_BG,
        payload
    }
}

export const setSidebarBg = (payload: any) => {
    localStorage.setItem('setSidebarBg', payload);
    return {
        type: SIDEBAR_BG,
        payload
    }
}

export const setTheme = (payload: any) => {
    localStorage.setItem('setTheme', payload);
    return {
        type: THEME,
        payload
    }
}

export const setDir = (payload: any) => {
    return {
        type: DIRECTION,
        payload
    }
}

export const setSidebarPos = (payload: any) => {
    return {
        type: SIDEBAR_POSITION,
        payload
    }
}

export const setHeaderPos = (payload: any) => {
    return {
        type: HEADER_POSITION,
        payload
    }
}

export const setLayout = (payload: any) => {
    return {
        type: LAYOUT,
        payload
    }
}

export const setSidebarType = (payload: any) => {
    return {
        type: SIDEBAR_TYPE,
        payload
    }
}

export const setPermissions = (payload: any) => {
    storeStateInLocalStorage(LOCALSTORAGE_KEY.PERMISSION, payload);
    return {
        type: PERMISSIONS,
        payload
    }
}

export const setAssignedPermissions = (payload: any) => {
    return {
        type: ASSIGNED_PERMISSIONS,
        payload
    }
}

export const setAbilities = (payload: any) => {
    ability.update(payload);
    storeStateInLocalStorage(LOCALSTORAGE_KEY.ABILITIES, payload);
    return {
        type: ABILITIES,
        payload
    }
}

export const setTimezone = (payload: any) => {
    return {
        type: SELECTED_TIMEZONE,
        payload
    }
}

export const setDashboardFilter = (payload: any) => {
    return {
        type: SELECTED_DASHBOARD_FILTER,
        payload
    }
}