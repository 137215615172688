import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import './spinner.scss';
const Spinner = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        <>
            {
                promiseInProgress &&
                (
                    <div className='fallback-spinner1'>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>
                    </div>
                )
            }

            {/* <div className={promiseInProgress ? 'fallback-spinner' : 'fallback-spinner-disable'}>
                <div className={promiseInProgress ? 'loading component-loader' : ''}>
                    <div className="effect-1 effects"></div>
                    <div className="effect-2 effects"></div>
                    <div className="effect-3 effects"></div>
                </div>
            </div> */}
        </>

    )
}
export default Spinner;