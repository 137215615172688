import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { Ability, AnyAbility } from '@casl/ability';
import { configureStore } from '../redux/Store';

const storeData = configureStore().getState();
const abilities = storeData.settings ? storeData.settings.abilities : [];

const type = {} as AnyAbility;

export default new Ability(abilities);

const AbilityContext = createContext(type);
const Can = createContextualCan(AbilityContext.Consumer);

export { AbilityContext, Can };