import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pagination: {},
  columns: [],
  comparison_length: 0,
  showPanel: false,
};

export const MeterSettingSlice = createSlice({
  name: 'meterSetting',
  initialState,
  reducers: {
    ChangePagination: (state, action) => {
      state.pagination = action.payload
    },
    ChangeColumns: (state, action) => {
      state.columns = action.payload
    },
    ChangeComparisonLength: (state, action) => {
      state.comparison_length = action.payload
    },
    ChangeShowPanel: (state, action) => {
      state.showPanel = action.payload
    }
  },
});

export const {
  ChangePagination,
  ChangeColumns,
  ChangeComparisonLength,
  ChangeShowPanel
} = MeterSettingSlice.actions;

export default MeterSettingSlice.reducer;
