import React, { Suspense } from "react";

// project imports
import Loader from "./Loader";

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //
import { createBrowserHistory } from "history";
import { useLocation, useNavigate } from "react-router-dom";

const Loadable = (Component) => (props) => {
  // const History = createBrowserHistory({ basename: "/" });
  const history = useLocation();
  const navigate = useNavigate();
  return (
    <Suspense fallback={<></>}>
      <Component {...props} history={history} navigate={navigate} />
    </Suspense>
  );
}

export default Loadable;
